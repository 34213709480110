<template>
  <!-- <div class="section-description">
      <h1 class="table-heading"> {{$t('manage_evidence.uploaded_Evidence')}} </h1>
      <a href="#" class="btn btn-primary float-end" @click="openUploadModal"><i class="material-icons">add</i>Upload File</a>
  </div>
  <div class="row">
    <div v-for="asset in claim.assets" class="col" :key="asset.id">

      <claim-evidence-file :asset="asset" :claim_id="claim.id" />

    </div>
  </div>

  <teleport to="body">
    <div class="modal fade" id="uploadEvidenceModal" tabindex="-1" aria-labelledby="uploadEvidenceModalTitle" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="uploadEvidenceModalTitle">Upload Evidence</h5>
              </div>
              <div class="modal-body">
                  
                <table class="table">
                  <thead>
                      <tr>
                          <th scope="col">{{ $t('claims.new.file') }} <span class="text-danger">*</span></th>
                          <th scope="col">{{ $t('claims.new.evidence_type') }} <span class="text-danger">*</span></th>
                          <th scope="col">{{ $t('claims.new.comments_on_evidence') }}</th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="file in evidence" :key="file.id">
                          <td>
                            <input :id="file.id + 'file'" placeholder="Select a file..."  accept="image/*,video/*,audio/*,application/pdf,application/msword,application/zip" @change="(event) => selectEvidence(event, file)" class="form-control" type="file">
                            <div class="invalid-feedback show" v-if="file.error">{{file.errorMsg}}</div>
                          </td>
                          <th scope="row">
                            <select :disabled="file.permanent === true" v-model="file.type" class="form-control" required>
                              <option value="" selected>- {{ $t('claims.new.select_a_type') }} -</option>
                              <option value="vehicle_image">{{ $t('claims.new.vehicleImage') }}</option>
                              <option value="till_receipt">{{ $t('claims.new.tillReceipt') }}</option>
                              <option value="driveoff_report">{{ $t('claims.new.drive_off_report') }}</option>
                              <option value="additional_evidence">{{ $t('claims.new.other') }}</option>
                            </select>
                            <div :id="file.id + 'type'" class="invalid-feedback">{{ $t('claims.new.valid_evidence_feedback') }}</div>
                          </th>
                          <td>
                            <input v-model="file.comments" class="form-control" type="text">
                          </td>
                          <td>
                            <i v-if="file.permanent !== true" class="material-icons text-danger v-icon" @click="removeEvidence(file)">delete_outline</i>
                          </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <div id="evidenceFeedback" class="invalid-feedback">{{ $t('claims.new.evidence_minimum') }}</div>
                        </td>
                        <td><i class="material-icons text-success v-icon" style="cursor: pointer;" @click="addEvidence">add</i></td>
                      </tr>
                  </tbody>
                </table>

              </div>
            </div>
        </div>
    </div>
  </teleport> -->

  <h1 class="table-heading">{{ $t("manage_evidence.uploaded_evidence") }}</h1>
  <div class="flex-center" style="justify-content: flex-end">
    <button class="btn btn-primary" @click="() => addAssetModal.show()">
      {{ $t("sites.site.upload_file") }}
    </button>
  </div>

  <div class="row" style="margin-top: 30px">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <p style="font-size: 16px">
            {{ $t("sites.site.preview") + ":" }} <span> </span>
          </p>
          <p style="font-style: italic">{{ $t("sites.site.select_file") }}</p>

          <!-- Asset image component -->
          <ClaimAssetImage
            v-if="selectedAsset !== null && !isPdf"
            :filename="selectedAsset.filename"
            :site_id="claim.site_id"
            :id="selectedAsset.id"
            :claim_id="selectedAsset.claim_id"
            :client_id="client_id"
            :endpoint="`evidence/${selectedAsset.filename}`"
          />
          <iframe v-if="selectedAsset !== null && isPdf" :src="pdf_url" width="100%" height="750px" min-height="500px;"></iframe>
        </div>
      </div>
    </div>

    <div class="col-12">
      <!-- No document uploaded -->
      <div v-if="claim.assets.length === 0" class="no-asset-container">
        <span> {{ $t("sites.data.no_document_uploaded") }} </span>
      </div>

      <!-- Uploaded documents -->
      <div v-else>
        <div class="card">
          <div class="card-body">
            <div class="card-header-container m-b-md">
              <table class="table table-striped m-t-lg m-b-lg">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Filename</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="claim.assets.length < 1">
                    <td colspan="3" class="no-data-container">
                      {{ $t("global.no_data") }}
                    </td>
                  </tr>
                  <tr
                    v-for="asset in claim.assets"
                    @click="() => selectAsset(asset)"
                    :key="`payment-${asset.id}`"
                    style="vertical-align: middle; cursor: pointer"
                  >
                    <td>{{ formatType(asset.type) }}</td>
                    <td>{{ asset.filename }}</td>
                    <td>
                      <button
                        v-if="selectedAsset?.id != asset.id"
                        class="btn btn-primary"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Asset Modal -->
    <div
      class="modal fade"
      id="addAssetModal"
      tabindex="-1"
      aria-labelledby="successModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-h-xxl p-v-xxl">
          <div class="modal-title flex-center">
            <h3 class="m-r-sm">
              {{ $t("sites.data.add_asset_to_site").toUpperCase() }}
            </h3>
          </div>
          <p class="text-danger" style="font-weight: 700; font-size: 15px">
            {{ $t("sites.data.add_asset_message") }}
          </p>
          <div class="input-container m-b-sm">
            <label for="" class="custom-file-label"> {{ file_name }} </label>
            <input
              type="file"
              class="custom-file-input"
              accept="image/*,video/*,audio/*,application/pdf,application/msword,application/zip"
              @change="selectFile"
            />
          </div>
          <div>
            <label class="form-label m-b-xxs">
              Evidence Type <span class="text-danger">*</span>
            </label>
            <select v-model="newAsset.type" class="form-select m-b-sm">
              <!-- Add a placeholder for dropdown -->
              <option value="" selected disabled>Select a type</option>
              <option value="vehicle_image">
                {{ $t("evidences.vehicle_image") }}
              </option>
              <option value="till_receipt">
                {{ $t("evidences.till_receipt") }}
              </option>
              <option value="transfer_of_liability">
                {{ $t("evidences.transfer_of_liability") }}
              </option>
              <option value="dvla_response">
                {{ $t("evidences.dvla_response") }}
              </option>
              <option value="dvla_enquiry">
                {{ $t("evidences.dvla_enquiry") }}
              </option>
              <option value="notice">{{ $t("evidences.notice") }}</option>
              <option value="other">{{ $t("evidences.other") }}</option>
            </select>
          </div>
          <div class="flex-center">
            <button
              class="btn btn-secondary m-r-md"
              @click="() => addAssetModal.hide()"
            >
              {{ $t("global.cancel") }}
            </button>
            <button
              class="btn btn-primary"
              @click="uploadFile"
              :class="{
                disabled: newAsset.type === '' || newAsset.file === null,
              }"
            >
              {{ $t("sites.data.add_asset") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Asset Modal -->
    <div
      class="modal fade"
      id="deleteAssetModal"
      tabindex="-1"
      aria-labelledby="successModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-h-xxl p-v-xxl">
          <div class="modal-title flex-center">
            <h3 class="m-r-sm">
              {{ $t("blacklist.are_you_sure").toUpperCase() }}
            </h3>
            <i class="material-icons"> error_outline </i>
          </div>
          <p
            class="text-danger"
            style="font-weight: 700; font-size: 15px; text-align: center"
          >
            {{ $t("sites.data.delete_asset_message").toUpperCase() }}
          </p>
          <div class="flex-center">
            <button
              class="btn btn-secondary m-r-md"
              @click="() => deleteAssetModal.hide()"
            >
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="deleteAsset">
              {{ $t("blacklist.remove_it") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Timestamp from "../../../../../components/Timestamp.vue";
import ClaimEvidenceFile from "./ClaimEvidenceFile.vue";
import { v4 as uuidv4 } from "uuid";
import ClaimAssetImage from "../../../../../components/ClaimAssetImage.vue";
import { CLAIM_EVIDENCE_FILE_TYPES } from "../../../../../constants/constants";
import claimsService from "../../../../../services/claims.service";

export default {
  name: "ClaimEvidence",
  components: {
    Timestamp,
    ClaimEvidenceFile,
    ClaimAssetImage,
  },
  props: {
    claim: Object,
    client_id: Number | String,
  },
  data() {
    return {
      currentClaim: null,
      loadingClaim: true,
      evidence: [
        {
          id: uuidv4(),
          file: null,
          type: "vehicle_image",
          comments: null,
          error: false,
          errorMsg: "",
        },
      ],
      selectedAsset: null,
      pdf_url: null,
      showAllPages: false,
      addAssetModal: null,
      deleteAssetModal: null,
      newAsset: {
        file: null,
        type: "",
        loading: true,
      },
      types: CLAIM_EVIDENCE_FILE_TYPES,
      isAllChecked: false,
    };
  },
  mounted() {
    this.deleteAssetModal = new bootstrap.Modal(
      document.getElementById("deleteAssetModal")
    );
    this.addAssetModal = new bootstrap.Modal(
      document.getElementById("addAssetModal")
    );
  },
  methods: {
    formatType(type) {
      switch (type) {
        case "dvla_enquiry":
          return this.$t("evidences.dvla_enquiry");
        case "dvla_response":
          return this.$t("evidences.dvla_response");
        case "notice":
          return this.$t("evidences.notice");
        case "other":
          return this.$t("evidences.other");
        case "till_receipt":
          return this.$t("evidences.till_receipt");
        case "transfer_of_liability":
          return this.$t("evidences.transfer_of_liability");
        case "vehicle_image":
          return this.$t("evidences.vehicle_image");
        default:
          return type;
      }
    },
    selectFile(event) {
      if (event.target.files[0].size > 26214400) {
        file.errorMsg = this.$t("claims.new.file_size_error");
        file.error = true;
        event.target.value = "";
        event.target.classList.add("shake");
        setTimeout(() => {
          event.target.classList.remove("shake");
        }, 500);
        return;
      }
      let type = event.target.files[0].name.split(".").pop().toLowerCase();
      let invalidCount = 0;
      [
        "png",
        "jpg",
        "pdf",
        "jpeg",
        "mp4",
        "mp3",
        "mov",
        "avi",
        "webm",
        "gif",
      ].forEach((t) => {
        if (type.indexOf(t) === -1) {
          invalidCount++;
        }
      });
      if (invalidCount >= 10) {
        file.errorMsg = this.$t("claims.new.file_format_error");
        file.error = true;
        event.target.value = "";
        event.target.classList.add("shake");
        setTimeout(() => {
          event.target.classList.remove("shake");
        }, 500);
        return;
      }
      this.newAsset.file = event.target.files[0];
    },
    // addEvidence(){
    //   this.evidence.push({
    //     id: uuidv4(),
    //     file: null,
    //     type: "",
    //     comments: null,
    //     error: false,
    //     errorMsg: ""
    //   })
    // },
    uploadFile() {
      this.newAsset.loading = true;
      const data = new FormData();
      data.append("file", this.newAsset.file, this.newAsset.file.name);
      data.append("type", this.newAsset.type);
      data.append("client_id", this.client_id);
      claimsService
        .uploadEvidenceToClaim(
          this.client_id,
          this.claim.id,
          this.newAsset.file,
          this.newAsset.type
        )
        .then((response) => {
          console.log(response);
          this.claim.assets = response.data.assets;
          this.$success(this.$t("evidences.update_success"));
          this.addAssetModal.hide();
          // this.assets.push(response.data.assets);
        })
        .catch((error) => {
          console.log(error);
          this.$error(this.$t("evidences.update_failed"), error);
        });
    },
    handleDocumentRender() {
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    selectAsset(asset) {
      this.selectedAsset = asset;
      this.downloadPDF();
    },
    downloadPDF() {
      if (!this.isPdf) {
        console.log("something");
        this.pdf_url = "";
        return;
      }

      claimsService
        .downloadEvidence(
          this.client_id,
          this.selectedAsset.claim_id,
          this.selectedAsset.filename
        )
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          this.pdf_url = window.URL.createObjectURL(blob);
        })
        .catch((error) => {
          console.log(error);
          this.pdf_url = "";
        });
    },
    deleteAsset() {
      if (this.selectedAsset === null) return;
      claimsService
        .updateEvidenceType(
          this.$store.state.auth.user.selectedClient,
          this.claim.id,
          this.selectedAsset.id,
          "SOFTDELETED"
        )
        .then((response) => {
          console.log(response);
          const updatedList = this.claim.assets.filter(
            (asset) => asset.id !== this.selectedAsset.id
          );
          this.claim.assets = updatedList;
          this.selectedAsset = null;
          this.$success(this.$t("evidences.update_success"));
          this.deleteAssetModal.hide();
        })
        .catch((error) => {
          this.$error(this.$t("evidences.update_failed"), error);
        });
    },
    checkAll() {
      this.isAllChecked = true;
      this.claim.assets.forEach((asset) => {
        asset.checked = this.isAllChecked;
      });
    },
  },
  computed: {
    currentFileType: function () {
      if (this.selectedAsset) {
        return this.selectedAsset.filename
          .split(/[#?]/)[0]
          .split(".")
          .pop()
          .trim();
      }
    },
    isPdf: function () {
      return this.currentFileType === "pdf" ? true : false;
    },
    file_name: function () {
      if (this.newAsset.file == null) {
        return "Select File";
      }
      if (this.newAsset.file.name == null) {
        return "File Selected";
      }
      return this.newAsset.file.name;
    },
  },
};
</script>

<style scoped>
.delete-button:hover {
  color: red;
}
.delete-button {
  font-size: 14px;
  vertical-align: middle;
  margin-bottom: 4px;
  cursor: pointer;
}

.invalid-feedback {
  color: #ff4857 !important;
}

@keyframes shake {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake {
  -webkit-animation-name: shake;
  -webkit-animation-duration: 0.5s;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-iteration-count: infinite;
}

.no-asset-container {
  position: relative;
  top: 50%;
  left: 50%;
  font-style: italic;
  font-size: 16px;
}

.card-header-container {
  display: flex;
  justify-content: space-between;
  padding: 0;
  font-weight: 100;
}

.input-container {
  position: relative;
}

.delete_btn {
  /* color: white; */
}

.delete_btn:hover {
  color: var(--utility-red);
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
}
</style>
